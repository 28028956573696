import React, {useState} from 'react';
import {Button, Card, CardActions, CardContent, Typography} from "@material-ui/core";

const Drink: React.FC<Drink> = ({name, ingredients, recipeNotes}: Drink) => {
    const [showIngredients, setShowIngredients] = useState(false);
    const [showRecipeNotes, setShowRecipeNotes] = useState(false);
    return (
        <Card className="drink">
            <CardContent>
                <Typography variant="h5" component="h2">
                    {name}
                </Typography>
                {showIngredients && showRecipe(ingredients)}
                {showRecipeNotes && <div>{recipeNotes}</div>}
            </CardContent>
            <CardActions>
                <Button size="small" onClick={() => setShowIngredients(!showIngredients)}>
                    {showIngredients ? 'Hide Recipe' : 'Recipe'}
                </Button>

                <Button size="small" onClick={() => setShowRecipeNotes(!showRecipeNotes)}>
                    {showRecipeNotes ? 'Hide Recipe Notes' : 'Recipe Notes'}
                </Button>
            </CardActions>
        </Card>
    )
}

const showRecipe = (ingredients: Ingredient[]) => {
    return ingredients.map(ingredient => {
        return <div>
            <div><b>{ingredient.name}</b>: {ingredient.quantity}</div>
        </div>;
    });
}

interface Drink {
    name: string;
    ingredients: Ingredient[];
    recipeNotes: string;
}

interface Ingredient {
    name: string;
    quantity: string;
}

export default Drink