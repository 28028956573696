export const mockdata = [
    {
        name: "Old Fashioned",
        ingredients: [
            {
                name: "bourbon",
                quantity: "2 oz"
            },
            {
                name: "bitters",
                quantity: "4 dashes"
            },
            {
                name: "simple syrup",
                quantity: "1.5 tsp"
            },
            {
                name: "water",
                quantity: "1 tsp"
            }
        ],
        recipeNotes: "Combine and stir with large ice cube"
    },
    {
        name: "Marissa's Lemon Drop",
        ingredients: [
            {
                name: "Tito's Lemon Vodka",
                quantity: "2 oz"
            },
            {
                name: "Lemon Juice",
                quantity: "1 oz"
            },
            {
                name: "Simple Syrup",
                quantity: "1 oz"
            },
            {
                name: "Grand Marnier",
                quantity: ".5 oz"
            }
        ],
        recipeNotes: "Combine ingredients and shake with ice. Serve chilled"
    }
]