import React from 'react';
import './App.css';
import Drink from "./drink/drink";
import {mockdata} from "./constants/Constants";

const renderDrinks = () =>{
    return mockdata.map(drink => <Drink
        name={drink.name}
        ingredients={drink.ingredients}
        recipeNotes={drink.recipeNotes}
    />
    )
}

const App = () => {
    return (
        <div className="App">
            <div className="Drink-Wrapper">
                {renderDrinks()}
            </div>
        </div>
    );
}

interface drinkProp {
    name: string;
    ingredient: [];
}

export default App;
